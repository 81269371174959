
export function setupPostForm(iframeId: string, url: string, data: any): HTMLFormElement {
    const form = document.createElement('form');
    document.body.appendChild(form);

    form.setAttribute('method', 'POST');
    form.setAttribute('action', url);
    form.setAttribute('target', iframeId);

    for (const f in data) {
        form.appendChild(createHiddenInputField(f, data[f]));
    }

    return form;
}

export function createHiddenInputField(name: string, value: string): HTMLElement {
    const field = document.createElement('input');
    field.setAttribute('type', 'text');
    field.setAttribute('hidden', "");
    field.setAttribute('name', name);
    field.setAttribute('value', value || '');

    return field;
}

export function removeHTMLElement(elmt: HTMLElement) {
    return elmt.parentNode.removeChild(elmt);
}

export function autoCompleteKey(key) {
    return `${key}-${Date.now()}`;
}

export function escapeHTML(text: string): string {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, (m) => map[m]);
}
