import { ModelInterface } from './model.interface';

export class Card implements ModelInterface {
    id: number;
    type: string;
    number: string;
    cvv: string;
    exp_month: string;
    exp_year: string;
    created_at: string;
    updated_at: string;

    readonly CYBERSOURCE_CAR_TYPES = {
        'VISA': '001',
        'MASTERCARD': '002',
        'AMERICAN-EXPRESS': '003',
        'DISCOVER': '004',
        'DINERS CLUB': '005',
        'CARTE BLANCHE': '006',
        'JCB': '007',
        'ENROUTE': '014',
        'JAL': '021',
        'DANKORT': '034',
        'CARTES BANCAIRES': '036',
        'CARTA SI': '037',
        'UATP': '040',
        'MAESTRO': '042',
        'HIPERCARD': '050',
        'AURA': '051',
        'ELO': '054',
        'UNIONPAY': '062',
        'CARNET': '058'
    };

    /**
     * Class constructor
     * @param  {any = null} attrs
     */
    constructor(attrs: any = null) {
        if (attrs) {
            this.build(attrs);
        }
    }

    toCybersourceCardType(): string {
        return this.CYBERSOURCE_CAR_TYPES[this.type?.toLocaleUpperCase()] || '001';
    }

    toCybersourceExpDate(): string {
        let yearPrefix = '';
        if (this.exp_year.length === 2) {
            const year = new Date().getFullYear() + '';
            yearPrefix = year.substring(0,  year.length - 2);
        }

        return this.exp_month + '-' + yearPrefix + this.exp_year;
    }

    /**
     * Build the object attributes
     * @param {any} attrs
     */
    build(attrs: any): void {
        this.id = attrs.id;
        this.number = attrs.number;
        this.cvv = attrs.cvv;
        this.exp_month = attrs.exp_month;
        this.exp_year = attrs.exp_year;
        this.created_at = attrs.created_at;
        this.updated_at = attrs.updated_at;
    }

    /**
     * Return a JSON object representation of the object
     * @return {Object}
     */
    toJson(): Object {
        return {
            id: this.id,
            number: this.number,
            cvv: this.cvv,
            exp_month: this.exp_month,
            exp_year: this.exp_year
        };
    }
}
