import { Currency } from './currency';
import { ModelInterface } from './model.interface';
import { PhoneNumber } from './phone-number';

export class PaymentIntent implements ModelInterface {
    id: number;
    reference: string;
    amount: number;
    status: string;
    mode: string;
    currency: Currency;
    last_error_code: string;
    last_error_message: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    approved_at: string;
    canceled_at: string;
    declined_at: string;
    metadata: any;

    /**
     * Class constructor
     * @param  {any = null} attrs
     */
    constructor(attrs: any = null) {
        if (attrs) {
            this.build(attrs);
        }
    }

    /**
     * Build the object attributes
     * @param {any} data
     */
    build(attrs: any): void {
        this.id = attrs.id;
        this.reference = attrs.reference;
        this.amount = attrs.amount;
        this.status = attrs.status;
        this.mode = attrs.mode;
        this.last_error_code = attrs.last_error_code;
        this.last_error_message = attrs.last_error_message;
        this.created_at = attrs.created_at;
        this.updated_at = attrs.updated_at;
        this.deleted_at = attrs.deleted_at;
        this.approved_at = attrs.approved_at;
        this.canceled_at = attrs.canceled_at;
        this.declined_at = attrs.declined_at;

        if (attrs.currency) {
            this.currency = new Currency(attrs.currency);
        }

        if (attrs.metadata) {
            this.metadata = attrs.metadata;

            if (this.metadata.paid_phone_number) {
                this.metadata.paid_phone_number = new PhoneNumber(
                    this.metadata.paid_phone_number
                );
            }
        }
    }

    /**
     * Return a JSON object representation of the object
     * @return {any}
     */
    toJson(): Object {
        return {
            id: this.id,
            reference: this.reference,
            amount: this.amount,
            status: this.status,
            currency: this.currency.toJson()
        };
    }
}
