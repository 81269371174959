import { Component, h } from 'preact';
import { route } from 'preact-router';
import { TransactionVerify as TransVerify } from '../models';
import { TransactionService } from '../services';
import {
    redirectToPaymentMode, ReferrerService, Shared
} from '../utils';
import LoaderComponent from './loader.component';

interface Props {
    token?: string;
}

interface State {
    loadingState: string;
    loadingMessage?: string;
    loadingDetails?: string;
}

export default class TransactionVerifyComponent extends Component<Props, State> {
    service = new TransactionService;
    referrer = new ReferrerService;
    state: State = {
        loadingState: '',
        loadingMessage: '',
    };

    componentDidMount() {
        const token = this.props.token || this.referrer.getToken();
        this.setState({ loadingState: 'loading', loadingMessage: 'transaction_verify.verification' });

        this.service.verify(token)
            .then((transactionVerify: TransVerify) => {
                Shared.transaction = transactionVerify.transaction;

                if (transactionVerify.transaction.wasPaid()) {
                    Shared.token = null;
                    this.referrer.saveToken(null);
                    route('/status/success', true);
                } else {
                    Shared.verifyModel.next(transactionVerify);
                    Shared.token = token;

                    this.referrer.saveToken(token);
                    redirectToPaymentMode(transactionVerify);
                }
            }).catch(({ message }) => {
                this.setState({ loadingState: 'error', loadingMessage: '', loadingDetails: message });
                this.referrer.saveToken(null);
            });
    }

    render() {
        return (
            <div class="payment-container">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    { this.state.loadingState &&
                      <LoaderComponent state={ this.state.loadingState }
                        text={ this.state.loadingMessage }
                        details={ this.state.loadingDetails } /> }
                </div>
            </div>
        )
    }
}
