import { Transaction } from '../models';
import { ModelInterface } from './model.interface';
import { Setting } from './setting';
import { CREDIT_CARD_MODES, MOBILE_MONEY_MODES_WITH_COUNTRIES } from '../utils/payment-mode';

export interface ThemeInterface {
    page_background_color: string;
    background_color: string;
    button_color: string;
    button_text_color: string;
    nav_background: string;
    nav_text_active_color: string;
    nav_subtext_active_color: string;
    nav_background_active_color: string;
    header_background_color: string;
    logo_url: string;
}

export class TransactionVerify implements ModelInterface {
    settings: Array<{ setting: Setting, value: any }> = [];
    currency_modes: string[];
    transaction: Transaction;
    channels_settings: string[];
    allowed_payment_modes: string[];

    /**
     * Class constructor
     * @param  {any = null} attrs
     */
    constructor(attrs: any = null) {
        if (attrs) {
            this.build(attrs);
        }
    }

    getChannelsSetting(): string[] {
        if (this.channels_settings) {
            return this.channels_settings;
        }

        const setting = this.settings.find(s => {
            return s.setting.name === 'payment_channels' &&
                s.setting.group === 'preferences';
        });

        this.channels_settings = setting.value.filter((s: string) => s.trim() !== '');
        return this.channels_settings;
    }

    getAllowedPaymentModes(): string[] {
        if (this.allowed_payment_modes) {
            return this.allowed_payment_modes;
        }

        const setting = this.settings.find(s => {
            return s.setting.name === 'allowed_payment_modes' &&
                s.setting.group === 'preferences';
        });
        this.allowed_payment_modes = setting.value.filter((s: string) => s.trim() !== '');

        return this.allowed_payment_modes;
    }

    getAvailableModes(): string[] {
        let modes = this.transaction.currency.modes.filter(m1 => {
            return this.getChannelsSetting().filter(m2 => {
                return this.getAllowedPaymentModes().includes(m2)
            }).includes(m1);
        });

        // Filter with custom_metadata payment modes
        const method_modes = this.transaction.custom_metadata?.payment_modes;

        if (method_modes && Array.isArray(method_modes)) {
            modes = method_modes.filter(m => modes.includes(m));
        }

        // Filter with custom metadata payment countries
        const payment_countries = this.transaction.custom_metadata?.payment_countries;

        if (payment_countries && Array.isArray(payment_countries)) {
            let countries_modes = payment_countries.map(c => MOBILE_MONEY_MODES_WITH_COUNTRIES[c?.toLocaleUpperCase()]);
            countries_modes = [].concat.apply([], countries_modes);
            modes = countries_modes.filter(m => modes.includes(m));
        }

        return modes;
    }

    includesChannel(channel: string): boolean {
        return this.getChannelsSetting().includes(channel);
    }

    getCheckoutTheme(): ThemeInterface {
        const setting = this.settings.find(s => {
            return s.setting.name === 'checkout_theme' &&
                s.setting.group === 'preferences';
        });
        let default_theme: any = {};
        try {
            default_theme = JSON.parse(setting.setting.default_value);
        } catch (e) { }

        return setting ? Object.assign(default_theme, setting.value) : {};
    }

    getCardMode(): boolean {
        let card: string|boolean = false;
        this.getAvailableModes()
            .forEach(m => {
                if (CREDIT_CARD_MODES.includes(m)) {
                    card = m;
                }
            });

        return card;
    }

    getAdditionalFeesSettting() {
        const setting = this.settings.find(s => {
            return s.setting.name === 'transaction_fees_paid_by_client' &&
                s.setting.group === 'preferences';
        });

        return setting && setting.value;
    }

    getMoreInfoSettting() {
        const setting = this.settings.find(s => {
            return s.setting.name === 'process_more_info' &&
                s.setting.group === 'preferences';
        });

        return setting && setting.value;
    }

    getAskCustomerInformationSettting() {
        const setting = this.settings.find(s => {
            return s.setting.name === 'ask_customer_information_on_payment' &&
                s.setting.group === 'preferences';
        });

        console.log(setting);

        return setting && setting.value;
    }

    /**
     * Build the object attributes
     * @param {any} data
     */
    build(attrs: any): void {
        this.transaction = new Transaction(attrs.transaction);

        this.settings = attrs.settings.map(s => {
            return { setting: new Setting(s.setting), value: s.value }
        });
    }

    /**
     * Return a JSON object representation of the object
     * @return {Object}
     */
    toJson(): Object {
        return {};
    }
}
