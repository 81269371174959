import { Config } from '../app.configs';
import { Currency } from './currency';
import { Customer } from './customer';
import { ModelInterface } from './model.interface';
import { PhoneNumber } from './phone-number';
import { Shared } from '../utils';

export class Transaction implements ModelInterface {
    id: number;
    reference: string;
    description: string;
    callback_url: string;
    amount: number;
    status: string;
    customer_id: number;
    mode: string;
    commission: number;
    fees: number;
    fixed_commission: number;
    amount_transferred: number;
    amount_debited: number;
    customer: Customer;
    currency: Currency;
    last_error_code: string;
    last_error_message: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    approved_at: string;
    canceled_at: string;
    declined_at: string;
    refunded_at: string;
    transferred_at: string;
    metadata: any;
    custom_metadata: any;

    /**
     * Class constructor
     * @param  {any = null} attrs
     */
    constructor(attrs: any = null) {
        if (attrs) {
            this.build(attrs);
        }
    }

    hasCallbackUrl() {
        return this.callback_url && this.callback_url.trim() !== '';
    }

    wasPaid() : boolean {
        return [
            'approved', 'transferred', 'refunded', 'approved_partially_refunded',
            'transferred_partially_refunded'
        ].includes(this.status);
    }

    /**
     * Build the object attributes
     * @param {any} data
     */
    build(attrs: any): void {
        this.id = attrs.id;
        this.reference = attrs.reference;
        this.description = attrs.description;
        this.callback_url = attrs.callback_url;
        this.amount = attrs.amount;
        this.status = attrs.status;
        this.customer_id = attrs.customer_id;
        this.mode = attrs.mode;
        this.commission = attrs.commission;
        this.fees = attrs.fees;
        this.fixed_commission = attrs.fixed_commission;
        this.amount_transferred = attrs.amount_transferred;
        this.amount_debited = attrs.amount_debited;
        this.last_error_code = attrs.last_error_code;
        this.last_error_message = attrs.last_error_message;
        this.created_at = attrs.created_at;
        this.updated_at = attrs.updated_at;
        this.deleted_at = attrs.deleted_at;
        this.approved_at = attrs.approved_at;
        this.canceled_at = attrs.canceled_at;
        this.declined_at = attrs.declined_at;
        this.refunded_at = attrs.refunded_at;
        this.transferred_at = attrs.transferred_at;

        if (attrs.customer) {
            this.customer = new Customer(attrs.customer);
        }

        if (attrs.currency) {
            this.currency = new Currency(attrs.currency);
        }

        if (attrs.metadata) {
            this.metadata = attrs.metadata;

            if (this.metadata.paid_phone_number) {
                this.metadata.paid_phone_number = new PhoneNumber(
                    this.metadata.paid_phone_number
                );
            }
        }

        if (attrs.custom_metadata) {
            this.custom_metadata = attrs.custom_metadata;
        }
    }

    human_amount(amount = null) {
        if (this.customer.account_id == Config.evisaAccountId && this.custom_metadata?.custom_amount) {
            let amount = this.custom_metadata.custom_amount;
            let currency = this.custom_metadata.custom_currency;
            return ' '+(amount) + ' ' +(currency);
        }

        amount = amount === null ? this.amount : amount;
        amount = (amount / this.currency.div);
        const price = new Intl.NumberFormat(Shared.lang).format(amount);

        return (this.currency.prefix || '') + ' ' +
               price + ' ' +
               (this.currency.suffix || '');
    }

    /**
     * Return a JSON object representation of the object
     * @return {any}
     */
    toJson(): Object {
        return {
            id: this.id,
            reference: this.reference,
            description: this.description,
            callback_url: this.callback_url,
            amount: this.amount,
            status: this.status,
            customer: this.customer ? this.customer.toJson() : undefined,
            currency: this.currency.toJson()
        };
    }
}
