export interface ErrorData {
    message: string;
    errors: any;
}
export class BaseService {
    protected handleError(e: any) : Promise<ErrorData> {
        let message = '';
        let rawMessage = '';
        let errors: any = {};

        if (e.response) {
            if (e.response.data && e.response.data.message) {
                message = e.response.data.message;
                rawMessage = message;
            }

            if (e.response.data && e.response.data.errors &&
                Object.keys(e.response.data.errors).length > 0) {
                    errors = e.response.data.errors;
                    message += ':<ul>';
                    let messages = [];

                    for (const key in errors) {
                        let mess = errors[key];
                        if (mess instanceof Array) {
                            mess = mess.map(m => {
                                return `<li><strong>${key}</strong> ${m}</li>`
                            });
                            messages = messages.concat(mess);
                        } else {
                            messages.push(
                                `<li><strong>${key}</strong> ${mess}</li>`
                            );
                        }
                    }

                    message += messages.join('');
                    message += '</ul>';
            }
        } else {
            message = e.message;
            rawMessage = e.message;
        }

        return Promise.reject({ message, errors, rawMessage });
    }
}
