import { MemoryStorage } from './memory-storage';

export default class Store {
    private adapter: Storage;

    constructor(adapter: Storage = null) {
        if (adapter) {
            this.adapter = adapter;
        } else {
            try {
                this.adapter = window.localStorage;
            } catch(e) {
                this.adapter = new MemoryStorage;
            }
        }
    }

    setItem(k: string, v: string) {
        if (this.adapter) {
            this.adapter.setItem(k, v);
        }
    }

    getItem(k: string) {
        if (this.adapter) {
            return this.adapter.getItem(k);
        }
    }

    removeItem(k: string) {
        if (this.adapter) {
            this.adapter.removeItem(k);
        }
    }
}
