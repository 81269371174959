import { Component, Fragment, h } from 'preact';
import { Subscription } from 'rxjs';
import { TransactionFees, TransactionVerify } from '../models';
import { makeSureTransactionWasVerified, Shared } from '../utils';

interface State {
    transactionVerify?: TransactionVerify,
    transFees?: TransactionFees,
    mode?: string;
}

export default class MoreInfoComponent extends Component<any, State> {
    state: State = { };
    subscriptions: Subscription[] = [];

    constructor(props) {
        super(props);

        this.subscriptions.push(
            makeSureTransactionWasVerified(verifyTrans => {
                this.setState({ transactionVerify: verifyTrans });
            }),
            Shared.$paymentModeSub.subscribe((newMode) => {
                this.setState({ mode: newMode });
            }),
            Shared.$transactionFeesSub.subscribe((transFeesSub) => {
                if (transFeesSub) {
                    this.setState({ transFees: transFeesSub.transactionFees });
                }
            })
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    parseInfoText(text): string {
        if (!this.state.transactionVerify && !this.state.mode) {
            return '';
        }

        const amount = this.state.transactionVerify.transaction?.human_amount(
            this.state.transactionVerify.transaction.amount
        );

        const amount_debited = this.state.transactionVerify.transaction?.human_amount(
            this.state.transFees?.amount_debited
        );

        const fees = this.state.transactionVerify.transaction?.human_amount(
            this.state.transFees?.fees
        );

        return text.replace('{amount}', amount)
                .replace('{amount_debited}', amount_debited)
                .replace('{fees}', fees)
                .replace('{mode}', this.state.mode);
    }

    public render() {
        const moreInfo = this.state.transactionVerify?.getMoreInfoSettting();
        const visible = !! moreInfo;
        if (!visible) {
            return <Fragment />
        }

        return (
            <div class="alert alert-info d-flex align-items-center">
                <i className="fas fa-2x fa-info-circle mr-2"></i>
                { this.parseInfoText(moreInfo) }
            </div>
        );
    }
}
