import { h } from 'preact';
import { Link as StaticLink } from 'preact-router';
import { Match } from 'preact-router/match';

export const ActiveLink = ({ activeClassName, ...props }) => (
    <Match path={props.path || props.href}>
        { ({ matches }) => (
            <StaticLink
                {...props}
                class={[props.class || props.className, matches && activeClassName].filter(Boolean).join(' ')}
                style={ matches && props.activeStyle } />
        ) }
    </Match>
);
