import { Component, h } from "preact";
import { Text } from 'preact-i18n';

import {
    createMarkup, errocCodesToMessages,
} from '../../utils';

export interface Props {
    error: string;
    error_code?: any;
}

export class TranasctionErrorComponent extends Component<Props, any> {
    render() {
        return (
            <div class={`form-errors ${this.props.error ? '' : 'd-none'} `}>
                <p class="mb-0" dangerouslySetInnerHTML={createMarkup(this.props.error)} />
                { this.props.error_code &&
                    errocCodesToMessages(this.props.error_code).map(m => {
                        return <p class="mb-0 small font-weight-bold"> <Text id={ m } /> </p>
                    })
                }
            </div>
        )
    }
}
