import classnames from 'classnames';
import { Component, Fragment, h } from 'preact';
import { withText } from 'preact-i18n';
import { Subscription } from 'rxjs';
import { Transaction, TransactionFees } from '../models';
import { TransactionService } from '../services';
import { makeSureTransactionWasVerified, Shared, TransactionFeesSub, escapeHTML } from '../utils';
import { Config } from '../app.configs';

interface Props {
    loading: boolean;
    disabled: boolean;
    onClick?: () => void;
    buttonText?: string;
}

interface State {
    transaction?: Transaction,
    transFeesSub?: TransactionFeesSub,
    transaction_fees: any[];
    loading_fees: boolean;
}

@withText({
    pay_button_text: 'pay_button.text'
})
export default class PayButtonComponent extends Component<Props, State> {
    state: State = {
        transaction_fees: [],
        loading_fees: false,
    };

    transactionService = new TransactionService;
    subscriptions: Subscription[] = [];

    constructor(props) {
        super();

        this.subscriptions.push(
            makeSureTransactionWasVerified(verifyTrans => {
                this.setState({ transaction: verifyTrans.transaction });
            }),
            Shared.$transactionFeesSub.subscribe((transFeesSub) => {
                this.setState({ transFeesSub: transFeesSub });
            })
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    feesMarkup(trans_fees: TransactionFees) {
        if (trans_fees?.apply_fees_to_merchant !== false) {
            return <Fragment />;
        }

        return (
            <div class='alert alert-info fees-alert small show d-flex mb-2'>
                <i class='fad fa-exclamation-circle fa-2x mt-1 text-warning' />&nbsp;
                <div class="ml-2">
                    {this.oneFeeMarkup(trans_fees)}
                </div>
            </div>
        );
    }

    oneFeeMarkup(trans_fees) {
        let fees = this.state.transaction?.human_amount(trans_fees.fees);

        // For E-Visa account
        if (this.state.transaction?.customer.account_id == Config.evisaAccountId &&
            this.state.transaction?.custom_metadata?.custom_amount) {
                fees = this.state.transaction?.custom_metadata.custom_fees_amount +
                    ' ' + this.state.transaction?.custom_metadata.custom_fees_currency;
        }

        const message = escapeHTML(trans_fees.message).replace('{fees}', `<strong>${fees}</strong>`);

        return (
            <Fragment>
                <span dangerouslySetInnerHTML={{ __html: message }} /><br />
            </Fragment>
        );
    }

    public render(trans) {
        let buttonType: string;
        let buttonPros: any = {}
        const buttonText = this.props.buttonText || trans.pay_button_text;

        if (!this.props.onClick) {
            buttonType = 'submit';
        } else {
            buttonType = 'button';
            buttonPros.onClick = this.props.onClick
        }

        return (
            <div class="pay-btn-container">
                <div class={classnames('fees-alert justify-content-center', { 'd-flex': this.state.transFeesSub?.loading })}>
                    <span><i class='fad fa-spinner-third fa-spin fa-lg'/></span>
                </div>
                {
                    !this.state.transFeesSub?.loading && this.feesMarkup(this.state.transFeesSub?.transactionFees)
                }

                <button class="btn btn-success d-flex"
                    disabled={this.props.disabled || this.props.loading}
                    type={buttonType}
                    {...buttonPros}>
                    <span class={classnames('mr-auto text', { 'd-none': this.props.loading })}>
                        { buttonText }
                        {
                            this.state.transaction?.human_amount(
                                this.state.transFeesSub?.transactionFees?.amount_debited
                            )
                        }
                    </span>
                    <span class={classnames({ 'd-none': this.props.loading })}>
                        <i class="fad fa-lg fa-arrow-circle-right" />
                    </span>
                    <span class={classnames('mx-auto', { 'd-none': !this.props.loading })}>
                        <i class="fad fa-spinner-third fa-pulse" />
                    </span>
                </button>
            </div>
        );
    }
}
