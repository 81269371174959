import { init, Integrations } from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing";
import { Config } from './app.configs';

init({
    dsn: Config.sentryDSN,
    environment: Config.appEnv,
    integrations: [
        new Integrations.Breadcrumbs({
          console: false
        }),
        new BrowserTracing()
    ],
});
