import { Component, h } from 'preact';
import { Text } from 'preact-i18n';
import { Transaction } from '../models';
import { makeSureTransactionWasVerified, Shared } from '../utils';
import { Subscription } from 'rxjs';
import classnames from 'classnames';

interface Props {
    show: boolean;
}

interface State {
    transaction?: Transaction;
    theme: { logo_url: string }
}

export default class ModalTitleComponent extends Component<Props, State> {
    state: State = {
        theme: { logo_url: '' }
    };

    subscription: Subscription;

    constructor() {
        super();

        this.subscription = makeSureTransactionWasVerified(verifyTrans => {
            const theme = verifyTrans.getCheckoutTheme();

            this.setState({
                transaction: verifyTrans.transaction,
                theme: { logo_url: theme.logo_url }
            });
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <div class={classnames("modal-header modal-component-title", {'d-none': !this.props.show, 'd-flex': this.props.show})}>
                <div class="mr-auto">
                    <img src={ this.state.theme.logo_url } hidden={!this.state.theme.logo_url}/>
                </div>
                <div class="text-right" id="modal-text-bloc">
                    <div>
                        <Text id="modal_title.merchant" />: { this.state.transaction ? this.state.transaction.customer.account.name : '' }
                    </div>
                    <div>
                        <Text id="modal_title.id" />: { this.state.transaction ? this.state.transaction.reference : '' }
                    </div>
                </div>
            </div>
        );
    }
}
