import { route } from 'preact-router';
import { TransactionVerify, Transaction } from '../models';
import { Shared } from './shared';
import { Subscription } from 'rxjs';
import { ReferrerService } from './referrer';

export function makeSureTransactionWasVerified(
    callback: (trans: TransactionVerify) => any = () => {},
    redirect = true
): Subscription {
    return Shared.$verifySub.subscribe(transVerify => {
        if (!transVerify && redirect) {
            return route('/', true);
        }

        callback(transVerify);
    });
}

export function processCallback(transaction: Transaction, queryParams: any, timeout = 2000) : boolean {
    let url: string = '';
    const referrer = new ReferrerService;

    if (transaction.hasCallbackUrl()) {
        url = referrer.getCallbackUrl(
            transaction, transaction.callback_url, queryParams
        );
    } else if (referrer.getReferer()) {
        url = referrer.getReferer();
        url = referrer.getCallbackUrl(transaction, url, queryParams);
    }

    const hasCallbackUrl = url.trim() !== '';

    if (hasCallbackUrl) {
        window.setTimeout(() => {
            window.location.href = url;
        }, timeout);
    }

    return hasCallbackUrl;
}

export function processTransactionCancelation() {
    makeSureTransactionWasVerified((verifyTrans: TransactionVerify) => {
        if (!verifyTrans) {
            return;
        }

        const transaction = verifyTrans.transaction;
        const status = Shared.intent?.status || transaction.status;
        processCallback(transaction, { status, close: 'true' }, 0);
    }, false)
}

export function createMarkup(__html: string): {__html: string} {
    return { __html };
}


export function errocCodesToMessages(code: string) {
    return code.split(':').map((code) => {
        return `transaction_status.error_codes.${code}`
    });
}
