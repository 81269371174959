import axios from 'axios';
import { Config } from '../app.configs';
import { TransactionVerify, PaymentIntent, TransactionFees } from '../models';
import { BaseService, ErrorData } from './base.service';

export class TransactionService extends BaseService {
    createPayment(mode: string, token: string, append = {}): Promise<PaymentIntent | ErrorData> {
        const params = {
            token, ...append,
            include: 'customer.phone_number,currency,customer.account'
        };
        return axios.post(Config.toApiUrl(mode), params)
                    .then((data: any) => {   
                        return new PaymentIntent(data.data['v1/payment_intent']);
                    })
                    .catch(this.handleError);
    }

    createCybersourcePayment(token: string, append = {}): Promise<any | ErrorData> {
        const params = {
            token, ...append,
            include: 'customer.phone_number,currency,customer.account'
        };
        return axios.post(Config.toApiUrl('cybersource'), params)
                    .then((response: any) => {
                        response.data.intent = new PaymentIntent(response.data.intent);

                        return response.data;
                    })
                    .catch(this.handleError);
    }

    paymentStatus(mode: string, token: string, pi_reference: string): Promise<PaymentIntent | ErrorData> {
        const params = {
            token, pi_reference,
            include: 'customer.phone_number,currency,customer.account'
        };
        return axios
            .post(Config.toApiUrl(`${mode}/callback`), params)
            .then((data: any) => {
                return new PaymentIntent(data.data['v1/payment_intent']);
            })
            .catch(this.handleError);
    }

    verify(token: string): Promise<TransactionVerify | ErrorData> {
        const params = {
            token,
            include: 'transaction.customer.phone_number,transaction.currency,'+
                     'transaction.customer.account'
        };

        return axios({
            method: 'get',
            url: Config.toApiUrl('transactions/verify_token'),
            params
        }).then((response: any) => {
            return new TransactionVerify(response.data['v1/transaction_verify']);
        }).catch(this.handleError);
    }

    fees(token: string, mode: string): Promise<TransactionFees | ErrorData> {
        return axios({
            method: 'get',
            url: Config.toApiUrl('transactions/fees'),
            params: { token, mode }
        }).then((response: any) => {
            return new TransactionFees(response.data);
        }).catch(this.handleError);
    }
}
