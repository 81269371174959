import { Transaction } from '../models';
import Store from './store';

const REFERRER_KEY = 'feda_proc_referrer';
const TOKEN_KEY = 'feda_proc_token';

export class ReferrerService {
    private storage = new Store;

    saveToken(token: string) {
        if (token) {
            this.storage.setItem(TOKEN_KEY, token);
        } else {
            this.storage.removeItem(TOKEN_KEY);
        }
    }

    getToken() {
        return this.storage.getItem(TOKEN_KEY);
    }

    saveReferer() {
        if (typeof document === 'undefined' && typeof window === 'undefined') {
            return;
        }

        this.storage.removeItem(REFERRER_KEY);
        const referrer = document.referrer;

        const origin = window.location.origin;

        if (referrer && referrer.indexOf(origin) === -1) {
            this.storage.setItem(REFERRER_KEY, referrer);
        } else {
            this.storage.removeItem(REFERRER_KEY);
        }
    }

    getReferer() {
        return this.storage.getItem(REFERRER_KEY);
    }

    getCallbackUrl(transaction: Transaction, url: string = null, queryParams: any = {}) {
        if (!url) {
            const referrer = this.storage.getItem(REFERRER_KEY);
            url = referrer || transaction.callback_url;
        }

        queryParams.id = transaction.id;
        const query = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');

        url = (!url) ? "": url.trim();
        url += url.indexOf('?') === -1 ? '?' : '&';
        url += query;

        return url;
    }
}
