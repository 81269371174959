import { parsePhoneNumberFromString } from 'libphonenumber-js/custom'
import { ModelInterface } from './model.interface';

import metadata from '../assets/phone.metadata.json';

export class PhoneNumber implements ModelInterface {
    id: number;
    number: string;
    country: string;
    created_at: string;
    updated_at: string;

    /**
     * Class constructor
     * @param  {any = null} attrs
     */
    constructor(attrs: any = null) {
        if (attrs) {
            this.build(attrs);
        }
    }

    get phone() {
        try {
            return parsePhoneNumberFromString(
                this.number,
                this.country.toUpperCase() as any,
                metadata as any
            );
        } catch(e) {
            return null;
        }
    }

    get to_international() : string {
        return this.phone ? this.phone.formatInternational({ v2: false }) : '';
    }

    isFill() {
        return this.country && this.number && this.country.trim() !== '' &&
            this.number.trim() !== '';
    }

    /**
     * Build the object attributes
     * @param {any} attrs
     */
    build(attrs: any): void {
        this.id = attrs.id;
        this.number = attrs.number;
        this.country = attrs.country;
        this.created_at = attrs.created_at;
        this.updated_at = attrs.updated_at;
    }

    isValid() {
        return this.phone ? this.phone.isValid() : false;
    }

    /**
     * Return a JSON object representation of the object
     * @return {Object}
     */
    toJson(): Object {
        return {
            id: this.id,
            country: this.country,
            number: this.number
        };
    }
}
