import { Component, h } from 'preact';
import { Text } from 'preact-i18n';
import classnames from 'classnames';
import { Transaction } from '../models';
import { Config } from '../app.configs';
import {
    makeSureTransactionWasVerified, processCallback, Shared
} from '../utils';
import LoaderComponent from './loader.component';

interface Props {
    status?: string;
    errorCode?: string;
}

interface State {
    statusMessage: string;
    statusError?: string;
    statusState: string;
    processingCallbackUrl: boolean;
}

export default class TransactionStatusComponent extends Component<Props, State> {
    state: State = {
        statusMessage: '',
        statusState: '',
        processingCallbackUrl: false
    }

    componentDidMount() {
        makeSureTransactionWasVerified(() => {
            const transaction = Shared.transaction;
            if (!transaction) {
                return;
            }

            const status = Shared.intent?.status || transaction.status;
            const processingCallbackUrl = processCallback(transaction, { status });

            this.setState({ processingCallbackUrl });
        });
    }

    render() {
        return (
            <div class="payment-container">
                <div class="modal-body d-flex align-items-center justify-content-center flex-column">
                    <LoaderComponent state='success'
                        text='transaction_status.success'/>
                    <p class={classnames({'d-none': !this.state.processingCallbackUrl})}>
                        <i class="fad fa-lg fa-spinner-third fa-pulse" />
                    </p>
                </div>
            </div>
        )
    }
}
