import { Component, h } from 'preact';
import { route } from 'preact-router';
import { Text } from 'preact-i18n';
import classnames from 'classnames';
import { Subscription } from 'rxjs';
import { Transaction, PaymentIntent } from '../models';
import { TransactionService } from '../services';
import {
    makeSureTransactionWasVerified, Shared,
    checkIntentStatus
} from '../utils';
import PayButtonComponent from './pay-button.component';
import { TranasctionErrorComponent } from './shared/transaction-error.component';
import { CorisMoneyService } from '../services/coris-money.service';

interface Props { }
interface State {
    transaction?: Transaction
    loading: boolean,
    error?: string;
    codeOTP?: string;
    error_code;
}

export default class CorisMoneyComponent extends Component<Props, State> {
    state: State = {
        loading: false,
        error_code: null
    };
    transactionService = new TransactionService;
    corisMoneyService = new CorisMoneyService;
    token: string;
    subscription: Subscription;
    intent: PaymentIntent;

    componentDidMount() {
        this.subscription = makeSureTransactionWasVerified((verifyTrans) => {
            this.token = Shared.token;
            this.intent = Shared.intent;
            const transaction = verifyTrans.transaction;
            this.setState({ transaction });
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    formValid() {
        return this.state.codeOTP && this.state.codeOTP.trim() !== '';
    }

    handleSubmit = (e: any) => {
        e.preventDefault();

        if (!this.formValid()) {
            return;
        }

        this.setState({ loading: true });
        this.corisMoneyService.validate(
            this.state.codeOTP, this.token,
            this.intent.reference
        )
            .then((intent: PaymentIntent) => {
                checkIntentStatus(
                    this.transactionService,
                    'coris_money',
                    this.token,
                    this.intent.reference,
                    new Date(),
                    (_: PaymentIntent, mess: string) => {
                        this.setState({ error: mess, loading: false });
                    }
                );
            })
            .catch(({ message, errors }) => {
                this.setState({ error: message, loading: false });
            });
    }

    public render() {
        return (
            <form id="mobile-money" class="payment-container d-flex flex-column h-100" onSubmit={this.handleSubmit}>
                <fieldset class="modal-body mb-auto" disabled={ this.state.loading }>
                    <TranasctionErrorComponent error={this.state.error} error_code={this.state.error_code} />

                    <div class='form-group'>
                        <label for="otp"><Text id="mobile_money.otp" /></label>
                        <input id="otp" required
                            onInput={(e: any) => this.setState({codeOTP: e.target.value})}
                            type="text"
                            placeholder="Entrez le code de confirmation reçu"
                            class={`form-control ${this.formValid() ? '' : 'is-invalid'}`} />
                    </div>
                    <p class="small"><Text id="waiting_validation.coris_money" /></p>
                </fieldset>
                <div class="modal-footer">
                    <PayButtonComponent loading={ this.state.loading }
                        disabled={ !this.formValid() }/>
                </div>
            </form>
        )
    }
}
