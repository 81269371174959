import { route } from 'preact-router';
import { Config } from '../app.configs';
import { PaymentIntent } from '../models/payment-intent';
import { TransactionVerify } from '../models/transaction-verify';
import { TransactionService } from '../services/transaction.service';
import { Shared } from './shared';

export const MOBILE_MONEY_MODES_WITH_COUNTRIES = {
    BJ: [
        'mtn',
        'mtn_open',
        'mtn_ecw',
        'moov',
        'bmo',
        'sbin',
        'coris_money'
    ],
    TG: [
        'moov_tg',
        'togocel'
    ],
    CI: [
        'mtn_ci',
        'orange_ci',
        'moov_ci',
        'wave_ci',
        'wave_direct_ci'
    ],
    SN: [
        'orange_sn',
        'free_sn',
        'wave_sn',
    ],
    NE: [
        'airtel_ne',
    ],
    ML: [
        'orange_ml',
    ],
    GN: [
        'mtn_open_gn',
    ],
    BF: [
        'moov_bf',
        'orange_bf',
    ]
}
export const MOBILE_MONEY_MODES = [].concat.apply([], Object.values(MOBILE_MONEY_MODES_WITH_COUNTRIES));

export const MOBILE_MONEY_MODES_WITHOUT_PHONE = [
    // 'orange_ci',
    // 'orange_sn',
    'orange_ml',
    // 'wave_sn',
    // 'wave_ci',
    // 'orange_bf',
    // 'moov_bf',
];

export const CREDIT_CARD_MODES = [
    'stripe_gw',
    'stripe_gw_eu',
    'uba',
    'flutterwave',
    'flutterwave_eu',
    'cybersource'
];

export const TPE_MODES = [
    'ecobank_tpe',
    'orabank_tpe'
];

export function isMobile() {
    return window.matchMedia('(max-width: 500px)').matches;
}

export function redirectToPaymentMode(transVerify: TransactionVerify) {
    const cardEnabled = transVerify.getCardMode();

    if (isMobile()) {
        return route('/menu', true);
    }

    if (transVerify.transaction.customer.account_id == Config.evisaAccountId) {
        if(
            transVerify.transaction.mode &&
            cardEnabled &&
            isCardPayementMode(transVerify.transaction.mode)
        ) {
            routeTocard(cardEnabled);
        } else if (
            transVerify.transaction.mode &&
            isMobilePaymentMode(transVerify.transaction.mode)
        ) {
            route('/mobile-money', true);
        }  else if (cardEnabled) {
            routeTocard(cardEnabled);
        } else if (isMobilePaymentModeAllowed(transVerify)) {
            route('/mobile-money', true);
        } else {
            route('/404', true);
        }
    } else {
        if (
            transVerify.transaction.mode &&
            isMobilePaymentMode(transVerify.transaction.mode)
        ) {
            route('/mobile-money', true);
        } else if (
            transVerify.transaction.mode &&
            isCardPayementMode(transVerify.transaction.mode)
        ) {
            routeTocard(cardEnabled);
        } else if (isMobilePaymentModeAllowed(transVerify)) {
            route('/mobile-money', true);
        } else if (cardEnabled) {
            routeTocard(cardEnabled);
        } else {
            route('/404', true);
        }
    }
}

function routeTocard(cardMode) {
    switch (cardMode) {
        case 'stripe_gw':
        case 'uba':
        case 'cybersource':
            route('/card', true);
            break;
        case 'flutterwave':
            route('/card-wave', true);
            break;
        default:
            route('/404', true);
            break;
    }
}

export function isMobilePaymentMode(modes: string | string[]) {
    if (!modes) { return false; }

    if (!Array.isArray(modes)) {
        modes = [modes];
    }

    const diff = modes.filter(m => MOBILE_MONEY_MODES.includes(m));
    return diff.length > 0;
}

export function isMobilePaymentModeAllowed(transVerify: TransactionVerify) {
    const modes = transVerify.getAvailableModes();

    return isMobilePaymentMode(modes);
}

export function isCardPayementMode(modes: string | string[]) {
    if (!modes) { return false; }

    if (!Array.isArray(modes)) {
        modes = [modes];
    }

    const diff = modes.filter(m => {
        return CREDIT_CARD_MODES.includes(m)
    });
    return diff.length > 0;
}

export function checkIntentStatus(
    service: TransactionService,
    mode: string,
    token: string,
    payment_intent_reference: string,
    startTime: Date = new Date(),
    errorClb: (intent: PaymentIntent|null, mess: string) => void
) {
    service.paymentStatus(mode, token, payment_intent_reference)
        .then((intent: PaymentIntent) => {
            const now = (new Date()).getTime();
            const diff = (now - startTime.getTime()) / 60000;

            if (diff < 10) {
                if (intent.status === 'approved') {
                    Shared.intent = intent;
                    route('/status/success', true);
                } else if (intent.status === 'pending') {
                    setTimeout(() => {
                        checkIntentStatus(
                            service,
                            mode,
                            token,
                            payment_intent_reference,
                            startTime,
                            errorClb
                        );
                    }, 5000);
                } else { // Show
                    errorClb(intent, 'Transaction échouée. Veuillez reessayer');
                }
            } else {
                errorClb(intent, 'Transaction échouée. Veuillez reessayer');
            }
        })
        .catch(({ message }) => {
            errorClb(null, message);
        });
}
