import { environment } from './environment';

export const Config = {
    apiVersion: environment.API_VERSION,
    apiScheme: environment.API_SCHEME,
    apiDomain: environment.API_DOMAIN,
    appEnv: environment.APP_ENV,
    sentryDSN: environment.SENTRY_DSN,
    cybersource_url: environment.CYBERSOURCE_URL,
    stripePublicKey: environment.STRIPE_PUBLIC_KEY,
    evisaAccountId: parseInt(environment.EVISA_ACCOUNT_ID),
    guffeAccountId: parseInt(environment.GUFFE_ACCOUNT_ID),
    covidAccountId: parseInt(environment.COVID_ACCOUNT_ID),
    geoApiUrl: environment.GEO_API_URL,
    toApiUrl (path: string) {
        // tslint:disable-next-line:max-line-length
        return `${this.apiScheme}://${this.apiDomain}/${this.apiVersion}/${path}`;
    },
    toGeoApiUrl(path: string) {
        // tslint:disable-next-line:max-line-length
        return `${this.geoApiUrl}/${path}`;
    },
    in_mode (env: string) {
        return env === this.appEnv;
    }
};
