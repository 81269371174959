
export const environment = {
  APP_ENV: 'sandbox',
  API_SCHEME: 'https',
  API_DOMAIN: 'sandbox-api.fedapay.com',
  API_VERSION: 'v1',
  SENTRY_DSN: 'https://b6a11553ed3d4615927f5c12e0b524af@sentry.io/303039',
  CYBERSOURCE_URL: 'https://testsecureacceptance.cybersource.com/silent/embedded/pay',
  STRIPE_PUBLIC_KEY: 'pk_test_k36uM1OLbH5GeVPLuzmXf5vC00sXfUC93C',
  EVISA_ACCOUNT_ID: '1761',
  GUFFE_ACCOUNT_ID: 'undefined',
  COVID_ACCOUNT_ID: 'undefined',
  GEO_API_URL: 'https://geo.fedapay.com'
};
