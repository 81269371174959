import { Component, h } from "preact";
import { Text } from 'preact-i18n';

interface LoaderProps {
    state: string;
    text?: string;
    details?: string;
    details_fallback?: string;
}

export default class LoaderComponent extends Component<LoaderProps> {
    loadingImages = {
        loading: <img src="https://cdn.fedapay.com/img/logo-finance-white-animated.svg" />,
        error: <i class="fad fa-5x fa-exclamation-triangle" />,
        success: <i class="fad fa-5x fa-check" />
    };

    public render() {
        return (
            <div class="text-center loader-container">
                { this.loadingImages[this.props.state] }
                <p class="my-2 loader-message">
                    <Text id={ this.props.text } />
                </p>
                <p class="my-2 loader-message">
                    <Text id={ this.props.details || '' }>{ this.props.details_fallback }</Text>
                </p>
            </div>
        );
    }
}
