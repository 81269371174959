export class MemoryStorage implements Storage {
    readonly length: number = 0;

    private _data: any = {};

    setItem(id, val) {
        return this._data[id] = String(val);
    }

    getItem(id) {
        return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
    }

    removeItem(id) {
        return delete this._data[id];
    }

    clear() {
        return this._data = {};
    }

    key(index: number) {
        return Object.keys(this._data)[index];
    }
}
