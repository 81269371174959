import { Component, createRef, h } from 'preact';
import Downshift from 'downshift';
import * as classnames from 'classnames';
import { Text } from 'preact-i18n';
import { Observable, Subject, isObservable } from 'rxjs';
import { autoCompleteKey } from '../utils/dom';

interface Props {
    items: any[] | Observable<any[]>;
    typehead?: Subject<string>;
    trackBy?: string; // Track by Key
    valueKey?: string; // Value Key
    labelKey?: string; // Label key
    isInvalid?: boolean;
    onChange: Function;
    loading: boolean;
    placeholder?: string;
    inputId?: string;
    searchPlaceholder?: string;
    defaultItem?: any;
}

interface State {
    items: any[];
    selectedItem: any;
    keepItOpened: boolean;
}

export default class SelectComponent extends Component<Props, State> {
    state: State = {
        items: [],
        selectedItem: null,
        keepItOpened: false
    };
    clearSelection;
    reset;
    inputSearchRef = createRef();;

    componentDidMount() {
        if (isObservable(this.props.items)) {
            this.props.items.subscribe(items => {
                this.setState({ items });
            });
        } else {
            this.setState({ items: this.props.items });
        }

        // Default item
        if (this.props.defaultItem) {
            this.setState({ selectedItem: this.props.defaultItem });
        }
    }

    itemActive(item: any, selectedItem: any) {
        return this.itemTrackBy(item) === this.itemTrackBy(selectedItem);
    }

    itemValue(item: any) {
        if (item && this.props.valueKey) {
            return item[this.props.valueKey];
        } else {
            return item;
        }
    }

    itemLabel(item: any) {
        if (item && this.props.labelKey) {
            return item[this.props.labelKey];
        } else if(item) {
            return item;
        } else {
            return ''
        }
    }

    itemTrackBy(item: any) {
        if (item && this.props.trackBy) {
            return item[this.props.trackBy];
        } else {
            return this.itemValue(item);
        }
    }

    filter(searchTerm: string) {
        if (this.props.typehead) {
            this.props.typehead.next(searchTerm);
        } else {
            const items = (this.props.items as Array<any>).filter(item => {
                return !searchTerm || this.itemValue(item).includes(searchTerm)
            });

            this.setState({ items });
        }
    }

    clear() {
        this.setState({ selectedItem: null }, () => {
            this.clearSelection && this.clearSelection();
        });
    }

    clearAndKeepItOpened() {
        this.setState({ keepItOpened: true }, () => {
            this.clear();
        });
    }

    onChange(selection) {
        this.setState({ selectedItem: selection });
        this.props.onChange(selection);
    }

    onStateChange() {
        this.setState({ keepItOpened: false });
    }

    renderNotFoundItem() {
        return <a class="dropdown-item disabled text-center no-items">
            <Text id="card.no_items" />
        </a>
    }

    renderItems(getItemProps, selectedItem) {
        if (this.state.items.length == 0) {
            return this.renderNotFoundItem();
        }

        return (
            this.state.items
                .map((item, index) => (
                    <a class={classnames('dropdown-item', { active: this.itemActive(item, selectedItem)})}
                        {...getItemProps({
                            key: this.itemTrackBy(item),
                            index,
                            item
                        })}
                    >
                        {this.itemLabel(item)}
                    </a>
                ))
        );
    }

    renderSelectOrLoadingIcon(isOpen) {
        const loading = this.props.loading || false;

        if (loading) {
            return <i class="far fa-spinner-third fa-spin"></i>
        } else {
            return <i class={classnames('far', {'fa-angle-down': !isOpen, 'fa-angle-up': isOpen})}></i>
        }
    }

    setFocus(isOpen) {
        if (isOpen && this.inputSearchRef) {
            this.inputSearchRef.current.focus();
        }
    }

    render() {
        return (
            <Downshift
                onChange={selection => this.onChange(selection)}
                onInputValueChange={(inputValue) => this.filter(inputValue)}
                itemToString={item => this.itemLabel(item)}
                initialSelectedItem={ this.props.defaultItem }
                defaultIsOpen={this.state.keepItOpened}
                onStateChange={() => this.onStateChange()}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    selectedItem,
                    getRootProps,
                    toggleMenu,
                    clearSelection,
                    reset
                }) => {
                    this.clearSelection = clearSelection;
                    this.reset = reset;

                    this.setFocus(isOpen);
                    return (
                        <div class={classnames("selector-container dropdown", {'is-open': isOpen })}>
                            <div {...getRootProps({} as any, { suppressRefError: true })}>
                                <div class={classnames('input-group input-value-container', { 'is-invalid': this.props.isInvalid})}
                                    onClick={() => toggleMenu()}>
                                    <input id={this.props.inputId}
                                        type="text"
                                        class="form-control input-value"
                                        placeholder={this.props.placeholder}
                                        autoComplete={autoCompleteKey('select')}
                                        readOnly={true}
                                        value={this.itemLabel(this.state.selectedItem)}
                                        name={autoCompleteKey('select')}
                                    />
                                    <div class="input-group-append">
                                        <span class="separator"></span>
                                        <button class="btn btn-toggle" type="button">
                                            {this.renderSelectOrLoadingIcon(isOpen)}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class={classnames('dropdown-menu', { show: isOpen })} {...getMenuProps()}>
                                <div class="input-group input-group-sm input-search-container">
                                    <div class="input-group-prepend">
                                        <i class="far fa-magnifying-glass input-group-text"></i>
                                    </div>
                                    <input id={this.props.inputId} type="text"
                                        {...getInputProps()}
                                        ref={this.inputSearchRef}
                                        class="form-control input-search" placeholder={this.props.searchPlaceholder}
                                    />
                                    <div class="input-group-append">
                                        <span class="separator"></span>
                                        <button class="btn btn-clear" type="button" onClick={() => this.clearAndKeepItOpened()}>
                                            <i class="far fa-times"></i>
                                        </button>
                                    </div>
                                </div>

                                { isOpen ? this.renderItems(getItemProps, selectedItem) : null }
                            </div>
                        </div>
                    )
                }}
            </Downshift>
        );
    }
}
