import axios from 'axios';
import { Config } from '../app.configs';
import { Transaction, Card, PaymentIntent } from '../models';
import { BaseService, ErrorData } from './base.service';

export class GeoService extends BaseService {
    countries(q: string = ''): Promise<any> {
        return axios({
            method: 'get',
            url: Config.toGeoApiUrl('countries'),
            params: { q, limit: 200 }
        })
        .then((data: any) => data.data)
        .catch(this.handleError);
    }

    states(country_code: string, q: string = ''): Promise<any> {
        return axios({
            method: 'get',
            url: Config.toGeoApiUrl('states'),
            params: { country_code, q, limit: 200 }
        })
        .then((data: any) => data.data)
        .catch(this.handleError);
    }

    cities(country_code: string, q: string = ''): Promise<any> {
        return axios({
            method: 'get',
            url: Config.toGeoApiUrl('cities'),
            params: { country_code, q, limit: 200 }
        })
        .then((data: any) => data.data)
        .catch(this.handleError);
    }
}
