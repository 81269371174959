import classnames from 'classnames';
import { Component, h } from 'preact';
import { Text } from 'preact-i18n';
import { isMobile } from '../utils';

interface Props {
    show: boolean;
}

interface State {
    isMobile: boolean;
}

export default class MobileToolbarComponent extends Component<Props, State> {
    state: State = {
        isMobile: isMobile()
    };

    public render() {
        return (
            <div class={
                    classnames(
                        'mobile-toolbar btn-toolbar justify-content-between rounded-top',
                        {'d-none': !this.state.isMobile, show: this.props.show}
                    )
                }
                role="toolbar" aria-label="Toolbar with button groups">
                <a href="/menu" class="btn btn-link bg-transparent rounded-0 text-left">
                    <i class="fad fa-chevron-left"></i> <Text id="mobile_toolbar.back" />
                </a>
                <a href="/menu" class="btn btn-link bg-transparent rounded-0 text-right">
                    <Text id="mobile_toolbar.cancel" /> <i class="fad fa-times"></i>
                </a>
            </div>
        );
    }
}
