import axios from 'axios';
import { Config } from '../app.configs';
import { PaymentIntent } from '../models';
import { BaseService, ErrorData } from './base.service';

export class CorisMoneyService extends BaseService {
    validate(
        codeOTP: any, token: string, pi_reference: string
    ): Promise<PaymentIntent | ErrorData> {
        const params: any = {
            codeOTP, token, pi_reference,
            include: 'customer.phone_number,currency,customer.account'
        };

        return axios.post(
                        Config.toApiUrl('coris_money/validate'),
                        params
                    )
                    .then((data: any) => {
                        return new PaymentIntent(data.data['v1/payment_intent']);
                    })
                    .catch(this.handleError);
    }
}
