import { ModelInterface } from './model.interface';

export class TransactionFees implements ModelInterface {
    amount_debited: number;
    amount_transferred: number;
    apply_fees_to_merchant: boolean;
    commission: number;
    fees: number;
    fixed_commission: number;
    message: string;
    loading: boolean;

	/**
     * Class constructor
     * @param  {any = null} attrs
     */
    constructor(attrs: any = null) {
        if (attrs) {
            this.build(attrs);
        }
    }

    /**
     * Build the object attributes
     * @param {any} data
     */
    build(attrs: any): void {
        this.amount_debited = attrs.amount_debited;
        this.amount_transferred = attrs.amount_transferred;
        this.apply_fees_to_merchant = attrs.apply_fees_to_merchant;
        this.commission = attrs.commission;
        this.fees = attrs.fees;
        this.fixed_commission = attrs.fixed_commission;
        this.message = attrs.message;
    }

    /**
     * Return a JSON object representation of the object
     * @return {Object}
     */
    toJson(): Object {
        return {};
    }
}
