import './raven';
import './style/index.scss';
/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';

import axios from 'axios';
import AppComponent from './components/app.component';
import { Shared } from './utils';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    config.params = config.params || {};
    config.params.locale = Shared.lang;

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default AppComponent;
