import MicroModal from 'micromodal';
const MODAL_TEMPLATE = `
    <div class="modal__overlay" tabindex="-1">
        <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
            <button title="Fermer" class="modal__close" aria-label="Close modal" id="{{modal_id}}-close"></button>
            <iframe id="{{iframe_id}}" class="modal__iframe" src="{{link}}" frameborder="0" name="{{iframe_id}}"></iframe>
        </div>
    </div>
`;

export default class RedirectModal {
    static MODAL_DISMISSED = 0;
    static TRANSACTION_COMPLETE = 1;

    static create (modalId: string, iframeId: string, url: string) {
        let modal = document.getElementById(modalId);

        if (modal) {
            modal.parentNode.removeChild(modal);
        }

        const modalHtml = MODAL_TEMPLATE.replace(/\{\{link\}\}/g, url)
                                        .replace(/\{\{modal_id\}\}/g, modalId)
                                        .replace(/\{\{iframe_id\}\}/g, iframeId);


        modal = document.createElement('div');
        modal.innerHTML = modalHtml;
        modal.setAttribute('id', modalId);
        modal.setAttribute('class', 'modal micromodal-slide');
        modal.setAttribute('aria-hidden', 'true');
        document.body.appendChild(modal);
    }

    static open(modalId: string, onComplete = () => {}) {
        const onMessage = (e: any) => {
            if (e.origin.includes('api.fedapay.com')) {
                MicroModal.close(modalId);
            }
        };

        MicroModal.show(modalId, {
            debugMode: true,
            onClose: (modal: any) => {
                modal.parentNode.removeChild(modal);
                window.removeEventListener('message', onMessage);

                if (typeof onComplete === 'function') {
                    onComplete();
                }
            },
        });

        document.getElementById(`${modalId}-close`)
                .addEventListener('click', () => {
                    MicroModal.close(modalId);
                });

        window.addEventListener('message', onMessage);
    }

    static close(modalId: string) {
        MicroModal.close(modalId);
    }
}
