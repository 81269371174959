import { TinyColor } from '@ctrl/tinycolor';
import { ThemeInterface } from '../models/transaction-verify'

export function getContrastingColor(data: string): TinyColor {
    const col = new TinyColor(data);

    if (col.toHex() === 'transparent') {
      return new TinyColor('rgba(0,0,0,0.4)');
    }

    return new TinyColor(col.isDark() ? 'white' : 'black');
}

export function compileTheme(theme: ThemeInterface) {
    const bg = new TinyColor(theme.background_color);

    return Object.assign(theme, {
        // Computed colors
        nav_text_color: getContrastingColor(theme.nav_background).toString(), // Contrast of nav background color
        nav_border_color: getContrastingColor(theme.nav_background).setAlpha(0.05).toString(), // Contrast of nav background color + alpha 0.1
        text_color: getContrastingColor(theme.background_color).toString(), // Contrast of background color
        placeholder_color: getContrastingColor(theme.background_color).setAlpha(0.4).toString(), // Contrast of background color + alpha 0.4
        border_color: getContrastingColor(theme.background_color).setAlpha(0.1).toString(), // Contrast of background color + alpha 0.1
        input_focus_color: bg.isLight() ? bg.darken(5).toString() : bg.lighten(5).toString(), // Contrast of background color + alpha 0.1
        header_border_color: getContrastingColor(theme.header_background_color).setAlpha(0.05).toString(), // Contrast of header background color + alpha 0.1
        header_text_color: getContrastingColor(theme.header_background_color).toString(), // Contrast of header background color + alpha 0.1
        button_text_color: getContrastingColor(theme.button_color).toString(),
        button_hover_color: new TinyColor(theme.button_color).lighten(5).toString(),
        select_icon: `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23${getContrastingColor(theme.background_color).toHex()}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e`,
    });
}
