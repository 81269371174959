import { Component, h } from 'preact';
import { route } from 'preact-router';
import { Text } from 'preact-i18n';
import classnames from 'classnames';
import { Subscription } from 'rxjs';
import { Transaction, PaymentIntent } from '../models';
import { TransactionService } from '../services';
import {
     makeSureTransactionWasVerified, Shared,
    TPE_MODES
} from '../utils';
import PayButtonComponent from './pay-button.component';

interface Props { }
interface State {
    transaction?: Transaction
    tpe_token: string|null;
    mode: string|null;
    loading: boolean,
    error?: string;
    formValid: boolean;
    availableModes: string[]
}

export default class TpeComponent extends Component<Props, State> {
    state: State = {
        tpe_token: null,
        loading: false,
        formValid: false,
        mode: '',
        availableModes: [],
    };
    service = new TransactionService;
    token: string;
    subscription: Subscription;
    intent: PaymentIntent;

    componentDidMount() {
        this.subscription = makeSureTransactionWasVerified((verifyTrans) => {
            this.token = Shared.token;
            const transaction = verifyTrans.transaction;
            const modes = verifyTrans.getAvailableModes();

            const availableModes = TPE_MODES.filter((m) => modes.includes(m));
            const mode = transaction.mode || availableModes[0];

            this.setState({ mode, availableModes });
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    formValid() {
        return this.state.tpe_token && this.state.tpe_token.trim() !== '';
    }

    handleModeChange = (e: any) => {
        const mode = e.target.value

        this.setState({ mode });
    }

    handleSubmit = (e: any) => {
        e.preventDefault();

        if (!this.formValid()) {
            return;
        }

        this.setState({ loading: true });
        this.service.createPayment(
                this.state.mode, this.token,
                { tpe_token: this.state.tpe_token }
            )
            .then((intent: PaymentIntent) => {
                Shared.intent = intent;
                this.intent = intent;

                this.checkStatus(this.token, intent.reference);
            })
            .catch(({ message, errors }) => {
                this.setState({ error: message, loading: false });
            });
    }

    public render() {
        return (
            <form id="mobile-money" class="payment-container d-flex flex-column h-100" onSubmit={this.handleSubmit}>
                <fieldset class="modal-body mb-auto" disabled={ this.state.loading }>
                    <div class="form-group">
                    <label for="mode"><Text id="tpe.choose_bank" /></label>
                        <select class="custom-select"
                            value={ this.state.mode }
                            onChange={ this.handleModeChange }>
                            {
                                this.state.availableModes.map((m, k) => (
                                    <option value={ m } key={k}>
                                        <Text id={ `tpe.${m}` } />
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="token"><Text id="ecobank_tpe.token" /></label>
                        <input id="token" required={true}
                            type="text"
                            placeholder="Token de paiement"
                            onInput={(e: any) => this.setState({ tpe_token: e.target.value })}
                            class={classnames('form-control', {'is-invalid': !this.formValid()})}
                            />
                    </div>
                </fieldset>
                <div class="modal-footer">
                    <PayButtonComponent loading={ this.state.loading }
                        disabled={ !this.formValid() }/>
                </div>
            </form>
        )
    }

    private checkStatus(token: string, payment_intent_reference: string , startTime: Date = new Date()) {
        this.service.paymentStatus(this.state.mode, token, payment_intent_reference)
            .then((intent: PaymentIntent) => {
                const now = (new Date()).getTime();
                const diff = (now - startTime.getTime()) / 60000;

                if (diff < 10) {
                    if (intent.status === 'approved') {
                        Shared.intent = intent;
                        route('/status/success', true);
                    } else if(intent.status === 'pending') {
                        setTimeout(() => {
                            this.checkStatus(token, payment_intent_reference, startTime);
                        }, 5000);
                    } else { // Show
                        this.setState({ error: 'Transaction échouée. Veuillez reessayer', loading: false });
                    }
                } else {
                    this.setState({ error: 'Transaction échouée. Veuillez reessayer', loading: false });
                }
            })
            .catch(({ message }) => {
                // this.checkStatus(token, payment_intent_reference);
                this.setState({ error: message, loading: false });
            });
    }
}
