import { ModelInterface } from './model.interface';

export class Setting implements ModelInterface {
    id: number;
    name: string;
    group: string;
    default_value: string;
    data: any[];
    field_type: string;
    required: boolean;

	/**
     * Class constructor
     * @param  {any = null} attrs
     */
    constructor(attrs: any = null) {
        if (attrs) {
            this.build(attrs);
        }
    }

    /**
     * Build the object attributes
     * @param {any} data
     */
    build(attrs: any): void {
        this.id = attrs.id;
        this.name = attrs.name;
        this.group = attrs.group;
        this.default_value = attrs.default_value;
        this.data = attrs.data;
        this.field_type = attrs.field_type;
        this.required = attrs.required;
    }

    /**
     * Return a JSON object representation of the object
     * @return {Object}
     */
    toJson(): Object {
        return {};
    }
}
